import React from "react"
import Hero from "./Hero"
import { CallToAction } from "../../components/CallToAction"
import OurServices from "./OurServices"
import { HowItWorksHiringManagers } from "../../components/HowItWorks"
import Awards from "../../components/Awards"
import {
  WhyPeopleWorkWithUs,
  WhyPeopleContent,
} from "../../components/WhyPeopleWorkWithUs"
import { BG } from "../../components/atoms/Section"
import { Languages } from "../../provider"
import { useLang } from "../../components/Header/LanguageToggle/useLang"
import { awardsList } from "../../constants"
import { EmailType } from "../../utils/sendEmail"
import HeroText from "./HeroText"

const Page = () => {
  const lang = useLang()
  return (
    <div>
      <Hero />
      <HeroText />
      <OurServices />
      <WhyPeopleWorkWithUs type={WhyPeopleContent.hiringManagers} />
      <HowItWorksHiringManagers />
      <Awards awardsList={awardsList[lang]} />
      <CallToAction
        title="Need Help?"
        text={
          lang === Languages.EN
            ? "Enter your details and we will get in touch soon."
            : "お問合せ内容のご記入をお願いします。"
        }
        type={EmailType.default}
      />
    </div>
  )
}

export default Page
