import React from "react"
import { vpBlue } from "../../../assets/colors"

// <defs><style>.cls-1{fill:none;stroke:#a2a9ad;stroke-width:1.16px;}.cls-2{fill:#0055b8;}</style></defs>

export const Square: React.FC<{ width?: number; classes?: string }> = ({
  width = 100,
  classes,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 108.43 108.27"
      className={classes}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <rect
            strokeWidth={1.16}
            stroke="#a2a9ad"
            fill="none"
            x="49.97"
            y="0.58"
            width="57.88"
            height="57.88"
          />
          <rect
            strokeWidth={1.16}
            stroke="#a2a9ad"
            fill="none"
            x="23.93"
            y="41.95"
            width="42.38"
            height="42.38"
          />
          <rect
            strokeWidth={1.16}
            stroke="#a2a9ad"
            fill="none"
            x="0.58"
            y="74.05"
            width="33.64"
            height="33.64"
          />
          <path
            fill={vpBlue}
            d="M94.47,29.52A15.57,15.57,0,1,1,78.91,14,15.57,15.57,0,0,1,94.47,29.52"
          />
        </g>
      </g>
    </svg>
  )
}
