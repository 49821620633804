import React from "react"
import { HumanWay, DreamJob, CareerPlanning } from "./Content"
import { FadeInStaggered } from "../animations/FadeIn"
import { Title } from "../atoms/Title"
import {
  NativeIndustryLiteracy,
  LastingRelationships,
  WeGoBeyond,
} from "./Content/HiringManagers"
import { useDimensions } from "../hooks/useDimensions"

enum ColumnType {
  top = "start",
  mid = "center",
  btm = "end",
}

interface ColumnSectionProps {
  type: ColumnType
}

const ColumnSection: React.FC<ColumnSectionProps> = ({
  type = ColumnType.top,
  children,
}) => {
  return (
    <div className={`h-full mt-6 sm:mt-0 flex items-${type}`}>{children}</div>
  )
}

const Divider = () => {
  return <div className="h-full border-r border-gray-600"></div>
}

const ThreeReasons: React.FC<{ type: WhyPeopleContent; mobile?: boolean }> = ({
  type,
  mobile,
}) => {
  return (
    <div
      className="left-0 top-auto w-full h-full "
      style={{
        display: "grid",
        gridTemplateColumns: mobile ? "100%" : "1fr 2px 1fr 2px 1fr",
        gridColumnGap: "4rem",
        gridRowGap: "0",
      }}
    >
      <ColumnSection type={ColumnType.top}>
        <FadeInStaggered>
          {type === WhyPeopleContent.jobSeekers ? (
            <HumanWay />
          ) : (
            <NativeIndustryLiteracy />
          )}
        </FadeInStaggered>
      </ColumnSection>
      {!mobile && <Divider />}
      <ColumnSection type={ColumnType.top}>
        <FadeInStaggered>
          {type === WhyPeopleContent.jobSeekers ? (
            <DreamJob />
          ) : (
            <LastingRelationships />
          )}
        </FadeInStaggered>
      </ColumnSection>
      {!mobile && <Divider />}
      <ColumnSection type={ColumnType.top}>
        <FadeInStaggered>
          {type === WhyPeopleContent.jobSeekers ? (
            <CareerPlanning />
          ) : (
            <WeGoBeyond />
          )}
        </FadeInStaggered>
      </ColumnSection>
    </div>
  )
}

export enum WhyPeopleContent {
  jobSeekers = "jobSeekers",
  hiringManagers = "hiringManagers",
}

export const WhyPeopleWorkWithUs: React.FC<{ type: WhyPeopleContent }> = ({
  type,
}) => {
  const { width } = useDimensions()
  const mobile = width < 1000
  const result = (
    <div
      className="px-6 pt-16 pb-12 bg-white xs:px-10 md:px-10 md:pt-10 lg:px-12 lg:pt-12"
      style={{ height: mobile ? "auto" : "auto" }}
    >
      <FadeInStaggered>
        <Title>
          <span className="text-vp-blue">
            {type === WhyPeopleContent.hiringManagers
              ? "Why Do Clients Work With Us?"
              : "Why Do People Work With Us?"}
          </span>
        </Title>
      </FadeInStaggered>
      <div className="relative w-full h-full sm:mt-16">
        <ThreeReasons type={type} mobile={mobile} />
      </div>
    </div>
  )

  return mobile ? (
    result
  ) : (
    <div className="px-6 -mt-20 xs:px-10 sm:px-16 sm:pb-12">{result}</div>
  )
}
