import React from "react"

export const Diamond: React.FC<{ width?: string; classes?: string }> = ({
  width = 100,
  classes,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      className={classes}
      viewBox="0 0 99.44 130.62"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <rect
            className="cls-1-diamond"
            x="15.06"
            y="46.24"
            width="69.32"
            height="69.32"
            transform="translate(-42.64 58.85) rotate(-45)"
          />
          <path
            className="cls-2-diamond"
            d="M65.29,15.57A15.57,15.57,0,1,1,49.72,0,15.57,15.57,0,0,1,65.29,15.57"
          />
        </g>
      </g>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <defs><style>
        .cls-1-diamond{fill:none;stroke:#a2a9ad;}
        .cls-2-diamond{fill:#0055b8;}
        </style></defs>
      `,
        }}
      />
    </svg>
  )
}
