import React from "react"
import { useLang } from "../../Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { TitleH4 } from "../../atoms/Title"
import { X } from "../../atoms/abstract/X"
import { Diamond } from "../../atoms/abstract/Diamond"
import { V } from "../../atoms/abstract/V"

interface ContentProps {
  titleMap: { [key in Languages]: JSX.Element }
  descriptionMap: { [key in Languages]: string }
}
export const Content: React.FC<ContentProps> = ({
  descriptionMap,
  titleMap,
}) => {
  const lang = useLang()

  return (
    <div>
      <TitleH4 title={titleMap[lang] || titleMap[Languages.EN]} />
      <p
        className="mt-2 text-base leading-tight tracking-tight"
        style={{ fontSize: 13 }}
      >
        {descriptionMap[lang]}
      </p>
    </div>
  )
}

export const NativeIndustryLiteracy = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex flex-col justify-end h-40">
        <V />
        <span className="mt-4">Industry Literacy</span>
      </div>
    ),
    [Languages.JP]: (
      <div className="flex flex-col justify-end h-40">
        <V />
        <span className="mt-4 text-sm">高い業界リテラシー</span>
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "Our team comprises of industry natives that recruit at the highest levels. Having experts who understand the technical requirements of your roles and identify candidates based on their capabilities, potential and culture fit to your organization—rather than scanning CVs for buzzwords. ",
    [Languages.JP]:
      "最適な人材を紹介するためには、それぞれの業界の最新動向はもちろん業界の奥底に潜む事情に精通していることが求められます。それに対応するため、バンテージポイントのキャリアコンサルタントの多くはクライアント企業様の業界経験者で構成されています。",
  }
  return <Content titleMap={title} descriptionMap={description} />
}

export const LastingRelationships = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex flex-col justify-end h-40">
        <X />
        <br className="h-2" />
        <span>
          Lasting Relationships with <br />
          Exclusive Candidates
        </span>
      </div>
    ),
    [Languages.JP]: (
      <div className="flex flex-col justify-end h-40">
        <X />
        <br className="h-2" />
        <span className="text-sm">優秀な人材との長期間のネットワーク </span>
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "The most impressive network is worthless without tight-knit relationships. The level of dialogue we have with top talent puts us in a truly unique position when it comes to advising our clients on their recruiting strategies.",
    [Languages.JP]:
      "深い信頼関係があってこそ、はじめて良質なネットワークを築くことができます。質の高い候補者様と長期間に渡り築き上げたネットワークは、クライアント企業様の採用戦略から、候補者様に企業の魅力を訴求するための、私たちの貴重な財産となっています。",
  }
  return <Content titleMap={title} descriptionMap={description} />
}

export const WeGoBeyond = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex h-40 align-bottom">
        <span className="self-end -mr-16">
          We go Beyond <br /> the Simple Introduction
        </span>

        <Diamond classes="-ml-4 mb-8 mr-6" />
      </div>
    ),
    [Languages.JP]: (
      <div className="flex h-40 align-bottom">
        <span className="self-end w-40 -mr-4 text-sm">
          紹介するだけでなく、並走する{" "}
        </span>

        <Diamond classes="ml-2 mr-4 mb-2" />
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "From deep-dive assessments of each talent we introduce to proactive follow-ups when they join your organization. Having a thoughtful talent acquisition strategy ensures a smooth integration process of the new hire.",
    [Languages.JP]:
      "採用企業様と候補者様のそれぞれと深く話し合うことで、書類に記載されている希望条件だけでなく、その背景にある想いや意志までしっかりとヒアリングし、ニーズを汲み取ります。新天地にスムーズに馴染んで頂けるようサポートする、 思いやりのある人材獲得戦略を提供しています 。",
  }
  return <Content titleMap={title} descriptionMap={description} />
}
