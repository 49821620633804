import React from "react"
import { vpBlue } from "../../../assets/colors"

// <defs><style>.cls-1{fill:none;stroke:#a2a9ad;}.cls-2{fill:#0055b8;}</style></defs>

export const Triangle: React.FC<{ width?: string; classes?: string }> = ({
  width = 100,
  classes,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 102.32 90.53"
      width={width}
      className={classes}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <polygon
            fill="none"
            stroke="#a2a9ad"
            points="1.43 13.96 70.48 89.69 101.54 35.88 1.43 13.96"
          />
          <path
            fill={vpBlue}
            className="cls-2"
            d="M44.69,13.41A13.41,13.41,0,1,1,31.28,0,13.41,13.41,0,0,1,44.69,13.41"
          />
        </g>
      </g>
    </svg>
  )
}
