import React from "react"
import { Section, BG } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import {
  ListCardWithoutBoxOnMobile,
  SimpleColumnContainer,
} from "../../../components/atoms/Cards"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { useIsMobile } from "../../../components/hooks/useDimensions"

const contentLeft = {
  [Languages.EN]: {
    title: "Retained Search",
    content: (
      <div className="text-gray-100">
        <p className="mt-2">
          CxOs, board members, managing directors, P&L owners, department heads
          and functional leaders
        </p>
        <br />
        <p>
          We pride ourselves at being one of the few talent acquisition firms
          with year-after-year retainer partnerships alongside our core clients.
        </p>
      </div>
    ),
  },
  [Languages.JP]: {
    title: "エグゼクティブ・サーチ（リテイナー型）",
    content: (
      <div className="mt-2 font-sans text-gray-100">
        <p>
          CxO、取締役／ボードメンバー、執行役員、戦略的経営幹部の紹介サービスです。一流の人材、そしてその資質をもつ人材を紹介し続けていることで、バンテージポイントは長きにわたりグローバル・トップ企業とのパートナーシップを築いています。
        </p>
      </div>
    ),
  },
}

const contentRight = {
  [Languages.EN]: {
    title: "Contingency Search",
    content: (
      <div className="text-gray-100">
        <p className="mt-2">
          Senior & middle management, specialists, time-sensitive hiring and
          independent contributors
        </p>
        <br />
        <p>
          VantagePoint executes senior contingent mandates with a solid
          track-record of placing candidates from the consulting world to other
          consultancies, local and global corporations as well as subject matter
          experts.
        </p>
      </div>
    ),
  },
  [Languages.JP]: {
    title: "コンティンジェンシー・サーチ（成功報酬型）",
    content: (
      <div className="mt-2 font-sans text-gray-100">
        <p>
          上級／中間管理職、スペシャリスト、早急に人材を採用する必要がある求人に対する人材紹介サービス。優秀な経営コンサルタントを他のコンサルティング会社（国内外問わず）に紹介するなど幅広く、豊富な実績があります。
        </p>
      </div>
    ),
  },
}

const OurServices = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  return (
    <Section bg={BG.blue}>
      <div className="flex flex-col justify-between pb-12 sm:pb-24 sm:flex-row">
        <Title>
          <span className="text-gray-100">Our Services</span>{" "}
        </Title>
        <div
          className="pb-0 mt-2 leading-snug sm:px-6 sm:pb-12"
          style={{ width: isMobile ? "100%" : 944 }}
        >
          <SimpleColumnContainer>
            <ListCardWithoutBoxOnMobile
              divisions={2}
              titleColor="white"
              listColor="gray-100"
              title={contentLeft[lang].title}
              content={contentLeft[lang].content}
            />
            <ListCardWithoutBoxOnMobile
              divisions={2}
              titleColor="white"
              listColor="gray-100"
              title={contentRight[lang].title}
              content={contentRight[lang].content}
            />
          </SimpleColumnContainer>
        </div>
      </div>
    </Section>
  )
}

export default OurServices
