import React from "react"
import { useLang } from "../../Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { Triangle } from "../../atoms/abstract/Triangle"
import { CircleLine } from "../../atoms/abstract/CircleLine"
import { Square } from "../../atoms/abstract/Square"
import { TitleH4 } from "../../atoms/Title"

interface ContentProps {
  titleMap: { [key in Languages]: JSX.Element }
  descriptionMap: { [key in Languages]: string }
}
export const Content: React.FC<ContentProps> = ({
  descriptionMap,
  titleMap,
}) => {
  const lang = useLang()

  return (
    <div>
      <TitleH4 title={titleMap[lang] || titleMap[Languages.EN]} />
      <p
        className="mt-2 text-base leading-tight tracking-tight"
        style={{ fontSize: 13 }}
      >
        {descriptionMap[lang]}
      </p>
    </div>
  )
}

export const HumanWay = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex flex-col justify-end h-40">
        <Triangle />
        <span className="mt-4">Recruiting the human way</span>
      </div>
    ),
    [Languages.JP]: (
      <div className="flex flex-col justify-end h-40">
        <Triangle />
        <span className="mt-4 text-sm">‘思いやりのある’紹介を行っています</span>
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "We are not in the business of filling as many positions as possible only to disappear immediately after a candidate has been successfully hired. Unlike many, we value relationships, never treating our candidates as commodities, while taking immense pride in helping them achieve their long-term career goals. ",
    [Languages.JP]:
      "私たちは、候補者様を単に欠員補充目的でご紹介することはありません。そして、ご紹介した方々が、転職後も継続的に目標を達成されることを願っています。",
  }
  return <Content titleMap={title} descriptionMap={description} />
}

export const DreamJob = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex flex-col justify-end h-40">
        <CircleLine />
        <br className="h-2" />
        <span>Helping you get your dream job—and thrive in it</span>
      </div>
    ),
    [Languages.JP]: (
      <div className="flex flex-col justify-end h-40">
        <CircleLine />
        <br className="h-2" />
        <span className="text-sm">
          理想の職に就くことがゴールではありません
        </span>
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "Our consultants will provide you with detailed coaching and insights helping you be your best during the interview process. We don’t stop there. Our team will continue to be proactive after you have joined your new company to ensure the right fit and integration. ",
    [Languages.JP]:
      "当社のコンサルタントは、応募書類のアドバイスや面接対策などきめ細やかなサポートを行うことで、万全の状態で転職活動を支援することはもちろん、ご転職後に活躍されるためのフォローまでしっかりとサポートしています。",
  }
  return <Content titleMap={title} descriptionMap={description} />
}

export const CareerPlanning = () => {
  const title = {
    [Languages.EN]: (
      <div className="flex h-40 align-bottom">
        <span className="self-end">
          Supporting <br /> Career Planning
        </span>

        <Square classes="-ml-4 mb-8 mr-6" />
      </div>
    ),
    [Languages.JP]: (
      <div className="flex h-40 align-bottom">
        <span className="self-end w-40 text-sm">
          キャリアプランニングのサポート
        </span>

        <Square classes="ml-2 mr-4" />
      </div>
    ),
  }
  const description = {
    [Languages.EN]:
      "As a talent acquisition firm, our services extend beyond traditional recruitment. We combine bespoke career coaching and step-by-step planning aimed at delivering true strategic value to your career development. ",
    [Languages.JP]:
      "キャリア戦略パートナーとして、私たちのサービスは既存のリクルートサービスの枠に収まることはありません。緻密に構築されたオーダーメイドのキャリアコーチングと段階的なプランニングは、理想的なキャリアに到達するために欠かすことのできないサービスとして取り組んでいます。 ",
  }
  return <Content titleMap={title} descriptionMap={description} />
}
