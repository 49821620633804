import React from "react"

export const V: React.FC<{ width?: string; classes?: string }> = ({
  width = 100,
  classes,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      className={classes}
      viewBox="0 0 107.2 106.64"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <path
            className="cls-1-V"
            d="M35,19.87a13.42,13.42,0,1,1-5.3-18.21A13.43,13.43,0,0,1,35,19.87"
          />
          <polyline
            className="cls-2-V"
            points="107.2 27.14 80.73 27.14 53.6 105.12 26.47 27.14 0 27.14"
          />
        </g>
      </g>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <defs><style>
        .cls-1-V{fill:#0055b8;}
        .cls-2-V{fill:none;stroke:#a2a9ad;}
        </style></defs>
      `,
        }}
      />
    </svg>
  )
}
