import React from "react"

export const X = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="max-w-xs"
      viewBox="0 0 274.16 76.4"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <line
            className="ã‚°ãƒ©ãƒ•ã‚£ãƒƒã‚¯ã‚¹ã‚¿ã‚¤ãƒ«-2"
            x1="0.9"
            y1="17.88"
            x2="273.26"
            y2="71.86"
          />
          <line
            className="cls-1-X"
            x1="273.26"
            y1="17.88"
            x2="0.9"
            y2="71.86"
          />
          <line
            className="ã‚°ãƒ©ãƒ•ã‚£ãƒƒã‚¯ã‚¹ã‚¿ã‚¤ãƒ«-2"
            x1="273.26"
            y1="17.88"
            x2="0.9"
            y2="71.86"
          />
          <path
            className="cls-2-X"
            d="M237.82,13.41A13.41,13.41,0,1,1,224.41,0a13.41,13.41,0,0,1,13.41,13.41"
          />
        </g>
      </g>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <defs><style>
        .cls-1-X,.ã‚°ãƒ©ãƒ•ã‚£ãƒƒã‚¯ã‚¹ã‚¿ã‚¤ãƒ«-2{fill:none;}
        .ã‚°ãƒ©ãƒ•ã‚£ãƒƒã‚¯ã‚¹ã‚¿ã‚¤ãƒ«-2{stroke:#a2a9ad;stroke-width:1.03px;}
        .cls-1{stroke:#fff;stroke-width:9.25px;}
        .cls-2-X{fill:#0055b8;}
        </style></defs>
      `,
        }}
      />
    </svg>
  )
}
