import React from "react"
import HiringManagers from "../pageComponents/HiringManagers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."

const HiringManagersPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Hiring Managers" />
      <HiringManagers />
    </Layout>
  )
}

export default HiringManagersPage
