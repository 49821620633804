import React from "react"
import { Section, BG } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import {
  TripleColumnContainer,
  ListCardWithoutBoxOnMobile,
} from "../../../components/atoms/Cards"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { useIsMobile } from "../../../components/hooks/useDimensions"

const contentLeft = {
  [Languages.EN]: {
    title: "Retained Search",
    content: (
      <div className="text-gray-100">
        <p className="mt-2">
          CxOs, board members, managing directors, P&L owners, department heads
          and functional leaders
        </p>
        <br />
        <p>
          We pride ourselves at being one of the few talent acquisition firms
          with year-after-year retainer partnerships alongside our core clients.
        </p>
      </div>
    ),
  },
  [Languages.JP]: {
    title: "エグゼクティブ・サーチ（リテイナー型）",
    content: (
      <div className="mt-2 font-sans text-gray-100">
        <p>
          CxO、取締役／ボードメンバー、執行役員、戦略的経営幹部の紹介サービスです。一流の人材、そしてその資質をもつ人材を紹介し続けていることで、バンテージポイントは長きにわたりグローバル・トップ企業とのパートナーシップを築いています。
        </p>
      </div>
    ),
  },
}

const contentRight = {
  [Languages.EN]: {
    title: "Contingency Search",
    content: (
      <div className="text-gray-100">
        <p className="mt-2">
          Senior & middle management, specialists, time-sensitive hiring and
          independent contributors
        </p>
        <br />
        <p>
          VantagePoint executes senior contingent mandates with a solid
          track-record of placing candidates from the consulting world to other
          consultancies, local and global corporations as well as subject matter
          experts.
        </p>
      </div>
    ),
  },
  [Languages.JP]: {
    title: "コンティンジェンシー・サーチ（成功報酬型）",
    content: (
      <div className="mt-2 font-sans text-gray-100">
        <p>
          上級／中間管理職、スペシャリスト、早急に人材を採用する必要がある求人に対する人材紹介サービス。当社には優秀な経営コンサルタントを他のコンサルティング会社（国内外問わず）に紹介するなど幅広く、豊富な実績があります。
        </p>
      </div>
    ),
  },
}

const Text = {
  [Languages.EN]: (
    <p className="mt-4 font-serif text-xl leading-tight sm:text-2xl">
      Looking beyond the obvious choices, identifying the right mix of
      qualifications, attitude and drive that makes the difference between an
      average hire and a great hire—someone who brings the potential to
      transform organizations.
    </p>
  ),
  [Languages.JP]: (
    <p className="mt-4 text-lg tracking-tighter sm:text-xl font-jp-serif">
      私たちは、固定概念に囚われず、優れた採用と組織変革のために最善の一手を探し続けます。
    </p>
  ),
}

const Copy = () => {
  const lang = useLang()

  return (
    <div className="z-10 max-w-6xl text-gray-100 ">
      <Title>
        <span className="leading-none tracking-normal text-gray-100">
          VantagePoint believes a circle can fit into a square
        </span>
      </Title>
      {Text[lang]}
    </div>
  )
}
const HeroText = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  return (
    <Section fullHeight>
      <div
        className="z-10 flex flex-col justify-end pb-24"
        style={{ height: 750, marginTop: -750 }}
      >
        <div className="flex flex-col justify-between sm:flex-row">
          {/* this is done in order to match responsive width */}
          <div className="opacity-0">
            <Title>
              <span className="text-gray-100">Our Services</span>{" "}
            </Title>
          </div>

          <div
            className="mt-2 leading-snug sm:px-6"
            style={{ width: isMobile ? "100%" : 944 }}
          >
            <Copy />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroText
