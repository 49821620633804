import React from "react"
import { vpBlue } from "../../../assets/colors"

// <defs><style>.cls-1{fill:#0055b8;}.cls-2{fill:none;stroke:#a2a9ad;}</style></defs>

export const CircleLine = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="max-w-xs"
      viewBox="0 0 284.22 87.18"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <path
            className="cls-1"
            fill={vpBlue}
            d="M87.47,59.75A13.42,13.42,0,1,1,74.06,46.33,13.42,13.42,0,0,1,87.47,59.75"
          />
          <line
            fill="none"
            stroke="#a2a9ad"
            x1="284.16"
            y1="52.07"
            x2="0.06"
            y2="86.68"
          />
          <circle
            fill="none"
            stroke="#a2a9ad"
            cx="56.82"
            cy="40.16"
            r="39.66"
          />
        </g>
      </g>
    </svg>
  )
}
