import React from "react"
import { Section, BG } from "../atoms/Section"
import { HowItWorksCard, SPACING } from "../atoms/Cards"
import { Title } from "../atoms/Title"
import { useDimensions } from "../hooks/useDimensions"
import { Languages } from "../../provider"
import { useLang } from "../Header/LanguageToggle/useLang"
import { WIDTH_BREAKPOINT } from "../../constants"

const DesktopContainerTalents: React.FC<{ mobile?: boolean }> = ({
  children,
  mobile,
}) => (
  <div
    className={`-mx-${SPACING}`}
    style={{
      display: "grid",
      gridTemplateColumns: mobile ? "100%" : "1fr 1fr 1fr 1fr",
      gridRowGap: "1rem",
    }}
  >
    {children}
  </div>
)

export type FlexibleComponentType = string | JSX.Element

export type ContentType = {
  title: FlexibleComponentType
  description: FlexibleComponentType
}

const talentsContent: { [key in Languages]: ContentType[] } = {
  [Languages.EN]: [
    {
      title: "Counseling",
      description:
        "Meeting for a counselling session with one of our consultants who will help you understand your market value and the current hiring environment. Our team will then advise you to find a suitable opportunity aligned to your goals. ",
    },
    {
      title: "Matching",
      description:
        "VantagePoint takes the time to listen and truly guide you to the best-suited opportunity. We prepare you for a carefully selected role with our strategic hiring partners. ",
    },
    {
      title: "Meetings",
      description:
        "Continuous feedback: interviews -> negotiations - > offer. We will be your partner at each stage seeking to secure the best possible conditions—on your behalf. ",
    },
    {
      title: "Follow-up",
      description:
        "Ongoing advice, industry mapping, career partnership—we are here to support you over the long run.",
    },
  ],
  [Languages.JP]: [
    {
      title: <p className="font-jp-serif">カウンセリング</p>,
      description: (
        <span>
          適正・能力、希望条件を考慮し、今後のキャリアプランについてカウンセリングを行います。
        </span>
      ),
    },
    {
      title: <p className="font-jp-serif">マッチング</p>,
      description: (
        <span>
          ご経験、希望条件などのご志向を伺いながら、キャリアプランについてご提案させて頂きます。
        </span>
      ),
    },
    {
      title: <p className="font-jp-serif">面接アレンジ</p>,
      description: (
        <span>
          内定を得るまでの転職活動が円滑に進むよう、サポートいたします。
        </span>
      ),
    },
    {
      title: <p className="font-jp-serif">フォローアップ</p>,
      description: (
        <span>
          担当キャリアコンサルタントがオファー条件の交渉や、退職時の交渉のサポートを、責任を持って遂行します。
        </span>
      ),
    },
  ],
}

export const HowItWorksTalents = () => {
  const lang = useLang()
  const dimensions = useDimensions()
  const isMobile = dimensions.width < WIDTH_BREAKPOINT
  const cards = (talentsContent[
    lang
  ] as ContentType[]).map((cardData: ContentType, i: number) => (
    <HowItWorksCard
      key={i}
      number={i + 1}
      title={cardData.title}
      description={cardData.description}
    />
  ))

  return (
    <Section>
      <Title content="How It Works" />
      <br className="h-10" />
      <DesktopContainerTalents mobile={isMobile}>
        {cards}
      </DesktopContainerTalents>
    </Section>
  )
}

const DesktopContainerHiringManagers: React.FC<{ mobile?: boolean }> = ({
  children,
  mobile,
}) => (
  <div
    className={`-mx-${4}`}
    style={{
      display: "grid",
      gridTemplateColumns: mobile ? "100%" : "1fr 1fr 1fr 1fr 1fr",
      gridRowGap: "1rem",
      overflowX: "auto",
    }}
  >
    {children}
  </div>
)

const hiringManagersContent: { [key in Languages]: ContentType[] } = {
  [Languages.EN]: [
    {
      title: (
        <span>
          Search <br /> Overview
        </span>
      ),
      description:
        "Conducting interviews with key members of your leadership and hiring team to understand your critical needs, formulate a strategy and match you with leading candidates.",
    },
    {
      title: "Candidate Selection",
      description:
        "Preparing a target list and market map of potential candidates using our in-house research team is the first step. From there, our consultants will contact each candidate ensuring your role is marketed extensively.",
    },
    {
      title: "Candidate Assessment",
      description:
        "1-to-1 interviews with potential candidates to assess skill set, career objectives, and organizational fit. We carefully analyze their profiles to determine if he or she has the right potential for your role.",
    },
    {
      title: "Introduction",
      description:
        "Our consultants will introduce the best candidates based on your needs while driving the meeting process carefully and providing essential feedback to your team.",
    },
    {
      title: "Interview / Follow-up ",
      description:
        "Once a lead candidate has been established, our team will work tirelessly to ensure your firm is represented in the best light and a tailored message is presented to the candidate.",
    },
  ],
  [Languages.JP]: [
    {
      title: "ニーズのヒアリング",
      description:
        "キャリアコンサルタントが貴社を訪問し、採用条件やニーズについてヒアリングをさせて頂きます。",
    },
    {
      title: "候補者の選定",
      description: "候補者の中から、貴社の採用条件に合う方を選出致します。",
    },
    {
      title: "候補者との面談",
      description:
        "選出した候補者の方と直接面談し、貴社の説明を行い、適性を判断します。コンサルタントが貴社に代わって候補者の方へ貴社の特徴や強みなどをお伝えします。",
    },
    {
      title: "貴社への推薦",
      description:
        "応募の意思を確認した候補者の推薦理由と履歴書·職務経歴書等を添えて、貴社に推薦します。",
    },
    {
      title: "書類選考、面接フォローアップ",
      description:
        "弊社が推薦した候補者を貴社にて選考いただきます。貴社に候補者の採用条件をアドバイスし、候補者が面接を通過し内定をいただけるまでのフォローを行います。",
    },
  ],
}

export const HowItWorksHiringManagers = () => {
  const lang = useLang()
  const dimensions = useDimensions()
  const isMobile = dimensions.width < 1000
  // const heightClass = dimensions.width < 1818 ? "h-12" : ""
  const cards = (hiringManagersContent[lang] as ContentType[]).map(
    (cardData: ContentType, i: number) => (
      <HowItWorksCard
        // titleClasses={!isMobile ? heightClass : ""}
        titleStyle={{ minHeight: "3rem" }}
        titleSizeClasses={
          lang === Languages.EN ? "text-2xl" : "text-xl leading-snug"
        }
        key={i}
        number={i + 1}
        title={cardData.title}
        description={cardData.description}
      />
    )
  )

  return (
    <Section bg={BG.gray}>
      <div className="pb-24">
        <Title content="How It Works" />
        <br className="h-10" />
        <DesktopContainerHiringManagers mobile={isMobile}>
          {cards}
        </DesktopContainerHiringManagers>
      </div>
    </Section>
  )
}
